$jq2(function(){
    //Dropdown for dealer
    $jq2('.dropdown').dropdown({
        onChange: function(value){
            //set cookie
            if($jq2.cookie('dealer_list_perpage') != value){
                $jq2.cookie('dealer_list_perpage', value, {path: '/' });
                window.location.reload();
            }
        }
    });
    //set dropdown default value
    $jq2('.dropdown').dropdown("set selected", $jq2.cookie('dealer_list_perpage'));

    $jq2('.js-checkbox-region').checkbox();

    //Toolstip
    var copySearch = document.querySelectorAll('.js-copy-search');

    $jq2('.js-copy-search').on('mouseleave', function(e) {
        e.currentTarget.setAttribute('class', 'btn');
        e.currentTarget.removeAttribute('aria-label');
    });

    function showTooltip(elem, msg) {
        elem.setAttribute('class', 'btn tooltipped tooltipped-s');
        elem.setAttribute('aria-label', msg);
    }

    // Simplistic detection, do not use it in production
    function fallbackMessage(action) {
        var actionMsg = '';
        var actionKey = (action === 'cut' ? 'X' : 'C');

        if(/iPhone|iPad/i.test(navigator.userAgent)) {
            actionMsg = 'No support! Press copy manual';
        }
        else if (/Mac/i.test(navigator.userAgent)) {
            actionMsg = 'Press ⌘-' + actionKey + ' to ' + action;
        }
        else {
            actionMsg = 'Press Ctrl-' + actionKey + ' to ' + action;
        }

        return actionMsg;
    }


    //Clipboard
    var clipboard = new Clipboard('[data-clipboard]');
        clipboard.on('success', function(e) {
            e.clearSelection();

            // console.info('Action:', e.action);
            // console.info('Text:', e.text);
            // console.info('Trigger:', e.trigger);

            showTooltip(e.trigger, 'Copied!');
        });

        clipboard.on('error', function(e) {
            // console.error('Action:', e.action);
            // console.error('Trigger:', e.trigger);

            showTooltip(e.trigger, fallbackMessage(e.action));
        });


    //List & Grid Videw
    function responsiveCheck(){}

    $jq2('.js-grid').on('click', function(event){
        event.preventDefault();

        $jq2('.js-dealer__list .js-item').addClass('col-sm-4 grid-group-item');

        $jq2(this).addClass('active');
        $jq2('.js-list').removeClass('active');

        $jq2('.js-wrapper-list, .js-list-img').css({height : 'auto'});

        //add class col 4 to 12
        $jq2('.js-list-img').removeClass('col-xs-4 col-lg-2').addClass('col-xs-12');
        $jq2('.js-list-address').removeClass('col-xs-8 col-lg-10').addClass('col-xs-12');

        //set cookie
        $jq2.cookie('dealer_display', 'grid', {path: '/' });
    });
    $jq2('.js-list').on('click', function(event){
        event.preventDefault();

        $jq2('.js-dealer__list .js-item').removeClass('col-sm-4 grid-group-item');

        $jq2(this).addClass('active');
        $jq2('.js-grid').removeClass('active');

        //add class col 12 to 4
        $jq2('.js-list-img').removeClass('col-xs-12').addClass('col-xs-4 col-lg-2');
        $jq2('.js-list-address').removeClass('col-xs-12').addClass('col-xs-8 col-lg-10');

        //set cookie
        $jq2.cookie('dealer_display', 'list', {path: '/' });
    });

    //init display
    switch($jq2.cookie('dealer_display')){
        case 'list':$jq2('.js-list').click();break;
        case 'grid':$jq2('.js-grid').click();break;
    }

    //Check height in list for img promotion
    $(window).ready(responsiveCheck).resize(responsiveCheck);


});//End jQuery
